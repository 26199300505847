<!-- 注册 -->
<template>
  <div class="Register">
    <header>
      <div>
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div>
        <Button type="error" @click="$router.push('/')">登录</Button>
      </div>
    </header>
    <section>
      <div style="margin-bottom:40px">
        <Steps :current="current">
          <Step title="账号注册">
            <!-- <Form :model="formItem" :label-width="80">
            <FormItem label="手机号码">
              <Input v-model="formItem.mobile" placeholder="Enter something..."></Input>
            </FormItem>
          </Form> -->
          </Step>
          <Step title="密码设置"></Step>
          <Step title="个人认证"></Step>
        </Steps>
      </div>
      <!-- 第一步 -->
      <div class="current_one" v-show="current == 0">
        <Form :model="formItem" :label-width="100" :rules="ruleValidate" ref="formValidate">
          <FormItem label="手机号码" prop="mobile">
            <Input v-model="formItem.mobile" placeholder="请输入手机号" style="width:400px"></Input>
          </FormItem>

          <FormItem label="短信验证码" prop="code">
            <Input v-model="formItem.code" placeholder="请输入验证码" style="width:299px"></Input>
            <Button type="primary" @click="send" :loading="loading" :disabled='disabled'>{{text}}</Button>
          </FormItem>
          <FormItem style="margin-top:50px">
            <Button type="warning" @click="handleSubmit('formValidate')">继续填写</Button>
          </FormItem>
        </Form>
      </div>

      <!-- 第二步 -->
      <div class="current_one" v-show="current == 1">
        <Form :model="obj" :label-width="100" :rules="ruleValidate_tow" ref="formValidate_tow">
          <FormItem label="设置密码" prop="password">
            <Input v-model="obj.password" placeholder="请输入最少六位最多十位由数字或字母组成的密码" style="width:400px" type="password" password maxlength="10"></Input>
          </FormItem>

          <FormItem label="再次输入" prop="password_tow">
            <Input v-model="obj.password_tow" placeholder="请再次输入密码" style="width:400px" type="password" password maxlength="10"></Input>
          </FormItem>

          <FormItem style="margin-top:50px">
            <Button type="warning" @click="handleSubmit_tow('formValidate_tow')">继续填写</Button>
          </FormItem>
        </Form>
      </div>

      <!-- 第三步 -->
      <div class="current_one" v-show="current == 2">
        <Form :model="sub_data" :label-width="120" :rules="ruleValidate_three" ref="handleSubmit_three">
          <FormItem label="姓名" prop="name">
            <Input v-model="sub_data.name" placeholder="请输入您的姓名" style="width:400px"></Input>
          </FormItem>

          <FormItem label="公司名称" prop="companyname">
            <Input v-model="sub_data.companyname" placeholder="请输入您的公司名称" style="width:400px"></Input>
          </FormItem>

          <FormItem label="部门" prop="department">
            <Input v-model="sub_data.department" placeholder="请输入您所在的部门" style="width:400px"></Input>
          </FormItem>

          <FormItem label="职务" prop="position">
            <Input v-model="sub_data.position" placeholder="请输入您所担任的职务" style="width:400px"></Input>
          </FormItem>

          <FormItem label="邮箱" prop="email">
            <Input v-model="sub_data.email" placeholder="请输入您的邮箱" style="width:400px"></Input>
          </FormItem>

          <FormItem label="公司简称" prop="shortName">
            <Input v-model="sub_data.shortName" :maxlength="sub_data.companyname.length" placeholder="请输入您的公司简称" style="width:400px"></Input>
          </FormItem>

          <FormItem label="企业信用代码" prop="creditCode">
            <Input v-model="sub_data.creditCode" placeholder="请输入您的信用代码" style="width:400px"></Input>
          </FormItem>

          <!-- !!!!!!!! -->

          <FormItem style="margin-top:50px">
            <Button type="warning" @click="handleSubmit_three('handleSubmit_three')">完成</Button>
          </FormItem>
        </Form>
      </div>

    </section>

    <footer>

    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      text: '获取验证码',
      loading: false,
      current: 0,
      formItem: {
        mobile: '',
        code: '',
      },
      obj: {
        password: '',
        password_tow: '',
      },
      sub_data: {
        companyname:""
      },
      ruleValidate: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式不正确", trigger: "blur" }
        ],
        code: [
          { required: true, message: '请输入验证码' },
          { pattern: /^[0-9]{6}$/, message: "验证码格式不正确", trigger: "blur" }
        ],
      },
      ruleValidate_tow: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/, message: "密码格式不正确", trigger: "blur" }
        ],
        password_tow: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/, message: "密码格式不正确", trigger: "blur" }
        ],
      },
      ruleValidate_three: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        companyname: [
          { required: true, message: '请输入您的公司名称', trigger: 'blur' },
        ],
        department: [
          { required: true, message: '请输入您所在的部门', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '请输入您所担任的职务', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入您的邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确格式的邮箱', trigger: 'blur' }
        ],
        shortName: [
          { required: true, message: '请输入您的公司简称', trigger: 'blur' },
        ],
        creditCode: [
          { required: true, message: '请输入您的信用代码', trigger: 'blur' },
          { pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g, message: "请输入正确的企业信用代码", trigger: "blur" }
        ],




      }
    }
  },
  methods: {
    //第一步提交
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          //手机号及验证码都填写完毕
          this.app('enterpriseOperators', 'checkCode', {
            mobile: this.formItem.mobile,
            code: this.formItem.code
          }).then(res => {
            if (res.code == 200) {
              this.current = 1
            } else {
              this.$Message.warning(res.message);
            }
          })

        } else {
          this.$Message.warning('请填写完整信息');
        }
      })
    },
    //第二步提交
    handleSubmit_tow(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.obj.password == this.obj.password_tow) {
            this.current = 2
          } else {
            this.$Modal.warning({
              title: "请输入相同密码！"
            })
          }

        } else {
          this.$Message.warning('请填写完整信息');
        }
      })
    },
    //第三步提交
    handleSubmit_three(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {

          //验证信用代码是否被注册
          this.app('enterpriseOperators', 'checkRegisterCreditCode', {
            creditCode: this.sub_data.creditCode
          }).then(res => {
            if (res.data) {
              this.$Modal.warning({
                title: "系统检测到该企业信用代码已被注册，可直接登录或重新填写。"
              })
            } else {
              this.sub_data.password = this.obj.password
              this.sub_data.mobile = this.formItem.mobile
              this.app('enterpriseOperators', 'registerEmployee', this.sub_data).then(res => {
                if (res.code == 200) {
                  console.log(res);
                  localStorage.setItem('firm_mobile', this.formItem.mobile)
                  localStorage.setItem('firm_token', res.data.token)
                  localStorage.setItem('firm_enterpriseId', res.data.enterpriseId)
                  this.$Modal.warning({
                    title: res.message
                  })
                  this.$router.push("Home")
                } else {
                  this.$Modal.warning({
                    title: res.message
                  })
                }
              })
            }
          })
        } else {
          this.$Message.warning('请填写完整信息');
        }
      })
    },
    //请求验证码
    send() {
      //验证手机号格式
      if (/^1[3-9][0-9]{9}$/.test(this.formItem.mobile)) {
        this.loading = true
        //验证是否已注册
        this.app('enterpriseOperators', 'checkRegisterMobile', {
          mobile: this.formItem.mobile
        }).then(res => {
          if (res.data.mobileRegister) {
            this.loading = false
            //已经被注册
            this.$Modal.warning({
              title: "该手机号已被注册，请勿重复注册！"
            })
          } else {
            //没有被注册
            this.app('enterpriseOperators', 'sendRegisterCode', { mobile: this.formItem.mobile }).then(res => {
              this.loading = false
              this.disabled = true
              this.text = '验证码已发短信,请输入'
            })

          }
        })
      } else {
        this.$Message.warning('请正确填写手机号码')
      }


    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
.Register {
  header {
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid #ccc;
    justify-content: space-around;
    align-items: flex-start;
  }
  section {
    padding-top: 50px;
    width: 50%;
    margin: 0 auto;
    .current_one {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
